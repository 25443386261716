import { motion } from 'framer-motion';
import React from 'react';
import { Container } from 'react-bootstrap';
import GlobalFooter from '../components/GlobalFooter';
import GlobalNavBar from '../components/GlobalNavBar';

const CV = () => {
    return (
        <motion.article
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
            <>
                <GlobalNavBar />
                <Container className="py-4">
                    <iframe className="d-block mx-auto mb-5" src="https://drive.google.com/file/d/122oNos1RPQIvDrAemLXoxFhhuT2L7qSk/preview" allow="autoplay" width="100%" height="800"></iframe>
                </Container>
                <GlobalFooter />
            </>
        </motion.article>
    )
}

export default CV;