import { motion } from "framer-motion";
import React, { useEffect } from "react";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from "react-router-dom";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import GW2 from "../../img/bg/GW2.jpeg";

const StylesDiv = styled.div`
  & {
    overflow-x: auto;
  }

  & img {
    width: 100%;

    @media only screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  & .grid-layout {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (min-width: 990px) {
      // grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const ResponsiveContainer = styled.div`
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    position: relative;
    margin-top: -10%;
    margin-bottom: 2rem;
  }
  @media (min-width: 990px) {
    width: 70%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.cover});
  background-size: cover;
  height: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    align-items: normal;
    height: 600px;
  }
`;

const Header = styled.div`
  height: fit-content;
  background-color: white;
  color: black;
  font-weight: bold;
  // mix-blend-mode: screen;
  font-family: "Oswald", sans-serif;
  text-shadow: 3px 3px 3px #ababab;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  padding: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 3rem;
    width: 60%;
  }
`;

const StyledArticle = styled(motion.article)`
  background-color: #f1f1f1;
`;

const LigoSurf2020 = () => {
  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <div>
      <StyledArticle
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <>
          <GlobalNavBar />
          <HeaderContainer cover={GW2}>
            <Header>LIGO: Acoustic Invisibility Cloak</Header>
          </HeaderContainer>
          <ResponsiveContainer className="container p-3">
            <StylesDiv>
              <p>
                During the Summer of 2020, I had the privilege of working with
                the Laser Interferometer Gravitational-Wave Observatory (LIGO)
                and Professor Rana Adhikari on a research project that was
                funded by the Richard Brewer Fellowship Prize. During this
                10-week period, I worked on an element of the LIGO Voyager
                Cryo-Q upgrade: the gravitational-wave detector had started to
                get so sensitive that it was detecting the thermal noise from
                its own Silicon atoms located in the Fabry-Perot mirror cavity.
                So, my research focused on finding a way to block the
                tranmission of noise (the natural vibrating frequency of
                Silicon) using band-limiting filters, and designing different
                acoustic geometries which I simulated using finite element
                analysis. See my final report below!
              </p>
              <iframe
                src="https://drive.google.com/file/d/1eDuB884wasiRaMif3AJ1MNfD5oe5yF5z/preview"
                width="100%"
                height="720"
              ></iframe>
            </StylesDiv>
          </ResponsiveContainer>
          <GlobalFooter />
        </>
      </StyledArticle>
      <ScrollRestoration />
    </div>
  );
};

export default LigoSurf2020;
