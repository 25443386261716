import { motion } from "framer-motion";
import React, { useEffect } from "react";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from "react-router-dom";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import RL from "../../img/bg/RL.jpeg";

const StylesDiv = styled.div`
  & {
    overflow-x: auto;
  }

  & img {
    width: 100%;

    @media only screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  & .grid-layout {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (min-width: 990px) {
      // grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const ResponsiveContainer = styled.div`
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    position: relative;
    margin-top: -10%;
    margin-bottom: 2rem;
  }
  @media (min-width: 990px) {
    width: 70%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.cover});
  background-size: cover;
  height: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    align-items: normal;
    height: 600px;
  }
`;

const Header = styled.div`
  height: fit-content;
  background-color: white;
  color: black;
  font-weight: bold;
  // mix-blend-mode: screen;
  font-family: "Oswald", sans-serif;
  text-shadow: 3px 3px 3px #ababab;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  padding: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 3rem;
    width: 60%;
  }
`;

const StyledArticle = styled(motion.article)`
  background-color: #f1f1f1;
`;

const StableSystems = () => {
  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <div>
      <StyledArticle
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <>
          <GlobalNavBar />
          <HeaderContainer cover={RL}>
            <Header>
              Online Adaptive Controller Selection for the Stable Control of
              Noisy Dynamical Systems
            </Header>
          </HeaderContainer>
          <ResponsiveContainer className="container p-3">
            <StylesDiv>
              <p>
                In the summer of 2020, I worked with Professor Adam Wierman and
                graduate student Yiheng Lin at the Rigorous Systems Research
                Group (RSRG) at Caltech, on a research project that was funded
                by the Associate’s Caltech SURF Fellowship. My research with
                Prof. Wierman focused on using perturbation analysis to prove
                stability bounds for model-predictive control on linear
                time-varying (LTV) systems in which the predictions of the
                disturbances are noisy, and extending this to linear feedback
                policies on the regularized online balanced descent (R-OBD)
                algorithm. We also showed more general results, which is
                published in our work (“Online Adaptive Controller Selection in
                Time-Varying Systems: No-Regret via Contractive Perturbation”,
                Y. Lin, J. Preiss, E. Anand, Y. Li, Y. Yue, A. Wierman), which
                is awaiting review at SIGMETRICS 2023. See our paper, and my
                first interim report below!
              </p>
              <iframe
                src="https://drive.google.com/file/d/1UGjmHKX82Yuc75vrnQW5ZQ5rtpaAzh7J/preview"
                width="100%"
                height="720"
              ></iframe>
              <iframe
                src="https://drive.google.com/file/d/1rNXdwpy6326X20I0kAmx7KLdze2Ymw2p/preview"
                width="100%"
                height="720"
              ></iframe>
            </StylesDiv>
          </ResponsiveContainer>
          <GlobalFooter />
        </>
      </StyledArticle>
      <ScrollRestoration />
    </div>
  );
};

export default StableSystems;
