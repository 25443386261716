export const publications = [
  {
    title: "Peer-to-Peer Learning Dynamics of Wide Neural Networks",
    journal: "Accepted to ICASSP 2025",
    authors: "Shreyas Chaudhari, Srinivasa Pranav, José M. F. Moura",
    url: "https://arxiv.org/abs/2409.15267",
  },
  {
    title: "Online Policy Optimization in Unknown Nonlinear Systems",
    journal: "Conference on Learning Theory (COLT) 2024",
    authors:
      "Yiheng Lin, James A Preiss, Fengze Xie, Soon-Jo Chung, Yisong Yue, Adam Wierman",
    url: "https://arxiv.org/abs/2404.13009",
  },
  {
    title:
      "Efficient Reinforcement Learning for Global Decision Making in the Presence of Local Agents at Scale",
    journal: "In Submission (NeurIPS 2024)",
    authors: "Guannan Qu",
    url: "https://arxiv.org/abs/2403.00222",
  },
  {
    title: "Mean-Field Sampling for Cooperative Multi-Agent Reinforcement Learning",
    journal: "In Submission (ICLR 2025)",
    authors: "Guannan Qu",
  },
  {
    title:
      "The Bit Complexity of Dynamic Algebraic Formulas and their Determinants",
    journal:
      "International Colloquium on Automata, Languages and Programming (ICALP) 2024",
    authors: "Jan Van den Brand, Mehrdad Ghadiri, Daniel Zhang",
    url: "https://arxiv.org/abs/2401.11127",
  },
  {
    title:
      "Online Adaptive Controller Selection in Time-Varying Systems: No-Regret via Contractive Perturbations",
    journal: "Advances in Neural Information Proceeding Systems (NeurIPS 2023)",
    authors: "Yiheng Lin, James Preiss, Yingying Li, Yisong Yue, Adam Wierman",
    url: "https://arxiv.org/abs/2210.12320",
  },
  {
    title:
      "Learning-augmented Control via Online Adaptive Policy Selection: No Regret via Contractive Perturbations",
    authors: "Yiheng Lin, James Preiss, Yingying Li, Yisong Yue, Adam Wierman",
    journal:
      "ACM SIGMETRICS Learning Augmented Algorithms: Theory and Applications (SIGMETRICS LATA 2023)",
    url: "https://learning-augmented-algorithms.github.io/papers/sigmetrics23-lata-posters-paper5.pdf",
  },
  {
    title: "Uncertainty Propagation in Dimensionality Reduction Embeddings",
    journal: "ArXiV preprint 2023",
    authors: "Sai Advaith Maddipatla",
    url: "https://drive.google.com/file/d/1GiRws2Neheayt8eOyOSVv7SaEi1r1VAI/view",
  },
  {
    title: "Identifying Chemicals Through Dimensionality Reduction",
    journal: "RSC-CICAG AI in Chemistry 2021",
    authors: "Charles Steinhardt, Martin Hansen",
    url: "https://arxiv.org/abs/2211.14708",
  },
];

export const talks = [
  {
    title:
      "Data-Adaptive Model Predictive Control of Linear Time Varying Systems With Imperfect Predictions",
    journal: "Caltech SFP Fall Seminar Day Presentations 2022",
    url: "https://www.youtube.com/watch?v=Mk7fGqwo300",
  },
  {
    title: `Presenting Cohn's construction of USP's for \\(O(n^{2.37})\\) matrix multiplication in the Coppersmith-Winograd Hashing`,
    journal:
      "Caltech CS 153 (Theoretical Ideas in Computer Science) Final Presentation",
    url: "https://www.youtube.com/watch?v=078Pg1VUZgc",
  },
  {
    title:
      "Solving a Water Crisis: Identifying Chemicals Using Dimensionality Reduction",
    journal:
      "Caltech SFP Fall Seminar Day Presentations 2021, Niels Bohr Institute (Cosmic Dawn Center) Summer Research Talks 2021",
    url: "https://www.youtube.com/watch?v=BOuxHPDvCkw&ab_channel=EmileTimothy",
  },
  {
    title: "Cryogenic High-Q Mechanical Resonator",
    journal: "LIGO DCC. LIGO Voyager Research Talks, 2020.",
    authors: "Srijan Gupta, Raymond Robie, Aaron Markowitz, Rana Adhikari",
    url: "https://dcc-backup.ligo.org/public/0170/T2000513/003/Srijan_Emile_Presentation.pdf",
  },
  {
    title: "TEDx: Envisioning time as a physical construct",
    journal: "TED talk: June 20, 2019",
    url: "https://www.youtube.com/watch?v=Eu4iAw_-stU",
  },
  {
    title: "What is the Theory of Computation?",
    journal:
      "Listen to Gabbie Chang and I chat about what CS theory is all about and why it is meaningful.",
    url: "https://www.youtube.com/watch?v=VOSomhXeH6A",
  },
];

export const misc = [
  {
    title: "Pseudorandomness of the Sticky Random Walk",
    journal: "Caltech Undergraduate Senior Thesis 2023",
    authors: "Chris Umans",
    url: "https://thesis.library.caltech.edu/16116/7/AnandCaltech_Final_Thesis_Submission2.pdf",
  },
  {
    title: "Caltech Ma 121 (Extremal Combinatorics): Lecture Notes",
    url: "https://drive.google.com/file/d/1xiwADvp8wD8Bh_Lik-plRoD5HByb-iyg/view",
  },
];
