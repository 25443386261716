import { motion } from "framer-motion";
import React, { useEffect } from "react";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from "react-router-dom";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import math_circle from "../../img/bg/math_circle.jpeg";

const StylesDiv = styled.div`
  & {
    overflow-x: auto;
  }

  & img {
    width: 100%;

    @media only screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  & .grid-layout {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (min-width: 990px) {
      // grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const ResponsiveContainer = styled.div`
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    position: relative;
    margin-top: -10%;
    margin-bottom: 2rem;
  }
  @media (min-width: 990px) {
    width: 70%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.cover});
  background-size: cover;
  height: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    align-items: normal;
    height: 600px;
  }
`;

const Header = styled.div`
  height: fit-content;
  background-color: white;
  color: black;
  font-weight: bold;
  // mix-blend-mode: screen;
  font-family: "Oswald", sans-serif;
  text-shadow: 3px 3px 3px #ababab;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  padding: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 3rem;
    width: 60%;
  }
`;

const StyledArticle = styled(motion.article)`
  background-color: #f1f1f1;
`;

const Exp = () => {
  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <div>
      <StyledArticle
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <>
          <GlobalNavBar />
          <HeaderContainer cover={math_circle}>
            <Header>
              Enlarging the Pseudorandomness of Expander Random Walks
            </Header>
          </HeaderContainer>
          <ResponsiveContainer className="container p-3">
            <StylesDiv>
              <p>
                For my senior thesis under the mentorship with{" "}
                <a href="http://users.cms.caltech.edu/~umans/">
                  Professor Christopher Umans
                </a>
                , I am working to enlarge the pseudorandomnesss of expander
                random walks by finding classes of functions that cannot
                distinguish between the vertices sampled from an expander walk
                on vertices labeled {`\\(\\{1,\\dots,n\\}\\)`} and the vertices
                sampled from the uniform distribution on {`\\([n]\\)`}. Building
                off of previous research (see below), we are working to show
                that expander random walks are fooled by functions computed by{" "}
                {`\\(\\mathrm{ACC}^0\\)`} circuits. See my fall write-up for the
                project below!
              </p>
              <iframe
                src="https://drive.google.com/file/d/1EN6dCjJIc8kDvk3TqfZP_-7PzBoi2xaM/preview"
                width="100%"
                height="720"
              ></iframe>
              <p>
                During my Junior year, I focused on understanding expander
                graphs. Over the year, we constructed a framework to study the
                model of random walks on expander graphs. A prior result shows
                that the sticky random walk can be a useful model to understand
                the full-scale expander graph. My research generalized the
                sticky random walk from {`\\(2\\)`} to {`\\(m\\)`} characters.
                See my write-up for the project below!
              </p>
              <iframe
                src="https://drive.google.com/file/d/1LReIqT5rFgB8UqtdJKAoxpKkeSUEEdn3/preview"
                width="100%"
                height="720"
              ></iframe>
            </StylesDiv>
          </ResponsiveContainer>
          <GlobalFooter />
        </>
      </StyledArticle>
      <ScrollRestoration />
    </div>
  );
};

export default Exp;
