import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import GlobalNavBar from "../components/GlobalNavBar";
import curie from "../img/about_me/curie.jpg";
import erdos from "../img/about_me/erdos.png";
import germain from "../img/about_me/germain.jpg";
import mirzakhani from "../img/about_me/mirzakhani.webp";
import noether from "../img/about_me/noether.png";
import ramanujan from "../img/about_me/ramanujan.png";
import shannon from "../img/about_me/shannon.png";
import turing from "../img/about_me/turing.png";
import emile1 from "../img/home-profile.jpg";

import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import GlobalFooter from "../components/GlobalFooter";
import { about_me } from "./text";

// const useParallax = (value, distance) => useTransform(value, [0, 1], [-distance, distance])

// const desc = [
//     <>
//         <h3>Hello! My name is Emile Timothy, and I'm a senior at Caltech majoring in Computer Science. I do research at the Theory of Computation Group and the Rigorous Systems Research Group (RSRG), and am a member of the Caltech Data Science team. My interests include working on research or coding projects, playing the Piano, thinking about wild hypothetical scenarios, and exploring boba stores and running trails around Pasadena and Los Angeles as a member of the Caltech XC/TF team. I occasionally post pictures and share my thoughts on Instagram and Twitter.</h3>

//         <h3>My research with Chris Umans focuses on constructing a framework to study the model of expander random walks, which is a useful tool in derandomization. My research is devoted to enlarging the pseudorandomness of expander random walks by finding test-functions that are unable to distinguish between expander random walks and truly random walks. We are currently trying to show that all functions computed by
//             circuits are fooled by expander random walks. My research with Adam Wierman focuses on using perturbation analysis to prove stability bounds for model-predictive control in noisy systems, and deriving linear feedback policies for the regularized online balanced descent (R-OBD) algorithm. Our work is currently awaiting review at . My research with Yaser Abu-Mostafa focuses on explaining why neural-network multi-classifiers outperform binary classifiers. To explain this, we are developing a theory of mutual cross-regularization between classes to prove a higher learning bound for multi-classifiers and training neural networks on large datasets (MNIST) to find the scope of this theory.</h3>
//     </>,
//     "An explorer of the unknown. I'm interested in creating robust machine learning algorithms and pushing the frontiers of computational limits, especially those concerning pseudorandomness.",
//     "My inspirations"]

// const imgs = [emile1, teaching]

// const Image = ({ id }) => {
//     const ref = useRef(null);
//     const { scrollYProgress } = useScroll({ target: ref });
//     const y = useParallax(scrollYProgress, 300);

//     return (
//         <motion.div className='image-wrapper'
//             initial="hidden"
//             animate="visible"
//             exit={{ opacity: 0, transition: { duration: 0.5 } }}>
//             <div ref={ref} className="rounded">
//                 <img src={imgs[id]} className="single-img rounded" alt="Emile Timothy" />
//             </div>
//             <motion.p style={{ y }} variants={{
//                 hidden: { opacity: 0, y: -20 },
//                 visible: { opacity: 1, y: 0, transition: 0.6 }
//             }}>
//                 {desc[id]}
//             </motion.p>
//         </motion.div>
//     );
// }

// const GridImage = ({ id }) => {
//     const ref = useRef(null);
//     const { scrollYProgress } = useScroll({ target: ref });
//     const y = useParallax(scrollYProgress, 500);

//     return (
//         <motion.div className='image-wrapper'
//             initial="hidden"
//             animate="visible"
//             exit={{ opacity: 0, transition: { duration: 0.5 } }}>
//             <div ref={ref} className="rounded">
//                 <section className='d-flex grid-image-wrapper'>
//                     <img src={shannon} />
//                     <img src={turing} />
//                     <img src={goog} />
//                     <section className="break"></section>
//                     <img src={sydney} />
//                     <img src={tim} />
//                     <img src={lamarr} />
//                 </section>
//             </div>
//             <motion.p style={{ y }} variants={{
//                 hidden: { opacity: 0, y: -20 },
//                 visible: { opacity: 1, y: 0, transition: 0.6 }
//             }}>
//                 {desc[id]}
//             </motion.p>
//         </motion.div>
//     );
// }

// const SectionContainer = styled.div`
//     min-height: 100vh;
//     position: relative;
//     background-image: url(${props => props.bgImg});
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;

//     .wrapper {
//         position: absolute;
//         top: 0;
//         left: 0;
//         height: 100%;
//         width: 100%;
//     }

//     h3, h5 {
//         background-color: rgba(0, 0, 0, 1);
//         color: white;
//         padding: 1.25rem;
//         border-radius: 0.25rem;
//     }
// `

// const GridImageContainer = styled.div`
//     display: grid;
// `

// const FeaturedItemContainer = styled.div`
//     display: flex;
//     margin-bottom: 1rem;

//     img {
//         width: 50px;
//         margin-right: 0.75rem;
//         border-radius: 0.25rem;
//     }

//     h3, h5 {
//         // font-size: 1.5rem;
//         margin-bottom: 0;
//     }
// `

// const FeaturedItem = ({ img, title, author }) => (<FeaturedItemContainer>
//     <motion.img whileHover={{ scale: 1.1 }} src={img} />
//     <div>
//         <h6>{title}</h6>
//         <p>{author}</p>
//     </div>
// </FeaturedItemContainer>)

// const MotionSectionContainer = motion(forwardRef((props, ref) =>
//     <SectionContainer ref={ref} {...props}>{props.children}</SectionContainer>))

// const ease = [0.08, 0.37, 0.45, 0.89]

const InspirationsContainer = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    width: 100%;
  }
  @media screen and (min-width: 1200px) {
    & > div {
      width: 75%;
    }
  }
`;

const ImageCaptionLink = styled.a`
  position: relative;
  display: block;
  margin-bottom: 0.25rem;

  & span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-decoration: none;
    color: white;
    padding: 0.25rem;
    margin-bottom: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.25rem;
  }

  @media screen and (min-width: 768px) {
    & span {
      opacity: 0;
      transition: opacity 0.5s;
    }

    &:hover span {
      opacity: 0.8;
    }
  }
`;

const PersonImageCaption = ({ href, src, name }) => (
  <ImageCaptionLink href={href}>
    <motion.img
      whileHover={{ scale: 1.01 }}
      src={src}
      className="d-block w-100 rounded"
    />
    <span>{name}</span>
  </ImageCaptionLink>
);

const mainContent = (
  <>
    <p>
      I am a research assistant at{" "}
      <motion.a
        whileHover={{ textDecorationLine: "underline", color: "#FF6347" }}
        href="https://www.cmu.edu/"
      >
        CMU's School of Computer Science
      </motion.a>
      , where I am fortunate to be advised by Professor{" "}
      <motion.a
        whileHover={{ textDecorationLine: "underline", color: "#FF6347" }}
        href="https://www.guannanqu.com/"
      >
        Guannan Qu
      </motion.a>
      .
    </p>
    <p>
      Currently, my research interests lie in theoretical computer science and
      theoretical machine learning. Recently, I've been thinking about densely
      networked multiagent reinforcement learning, Markov decision processes,
      and online balanced descent. I'm also interested in problems related to
      pseudorandomness against {`\\(\\mathrm{ACC}^0\\)`} circuits, Fourier
      analysis, and expander random walks.
    </p>
    <p>
      I graduated from{" "}
      <motion.a
        whileHover={{ textDecorationLine: "underline", color: "#FF6347" }}
        href="https://www.caltech.edu/"
      >
        Caltech
      </motion.a>{" "}
      with a BS in Computer Science, concentrated on theoretical CS and machine
      learning. I worked with Professor{" "}
      <motion.a
        whileHover={{ textDecorationLine: "underline", color: "#FF6347" }}
        href="http://users.cms.caltech.edu/~umans/"
      >
        Chris Umans
      </motion.a>{" "}
      on the pseudorandomness of expander random walks against varying levels of
      hardnesses near {`\\(\\mathrm{ACC}^0\\)`} circuits. I also worked with
      Professor{" "}
      <motion.a
        whileHover={{ textDecorationLine: "underline", color: "#FF6347" }}
        href="https://adamwierman.com/"
      >
        Adam Wierman
      </motion.a>{" "}
      on online adaptive control of general dynamical systems with sublinear
      regret &#8212; I coauthored a publication that achieved the first locally
      bounded regret against a non-convex system, which was accepted at NeurIPS
      2023. Before that, I worked with Professor{" "}
      <motion.a
        whileHover={{ textDecorationLine: "underline", color: "#FF6347" }}
        href="https://work.caltech.edu/"
      >
        Yaser Abu-Mostafa
      </motion.a>{" "}
      on developing the accuracy-expansion hypothesis for multi-classifier
      neural networks and building deep forecasting models for COVID-19
      outbreaks.
    </p>
    <p>
      Perhaps surprisingly, my academic journey began in physics. I spent much
      of my time in high-school studying problems in physics and doing
      competitions. In the summer of 2020, I had a fulfilling internship at the{" "}
      <motion.a
        whileHover={{ textDecorationLine: "underline", color: "#FF6347" }}
        href="https://labcit.ligo.caltech.edu/~rana/"
      >
        Laser Interferometer Gravitational-Wave Observatory
      </motion.a>
      , where I worked closely with Professor{" "}
      <motion.a
        whileHover={{ textDecorationLine: "underline", color: "#FF6347" }}
        href="https://scholar.google.com/citations?user=5wGd4NsAAAAJ&hl=en"
      >
        Rana Adhikari
      </motion.a>{" "}
      to study renormalizable operators for gravitons. In 2019, I had the
      privilege of representing the UAE at the International Physics Olympiad
      (IPhO). That same year, I also captained the UAE international debate team
      in the world schools debate championship.
    </p>
    <p>
      When I'm not working on research, I try to use my computer science skills
      for good. I currently serve as the campaign manager of the UN-affiliated
      youth-led NGO{" "}
      <motion.a
        whileHover={{ textDecorationLine: "underline", color: "#FF6347" }}
        href="https://www.thegreenhopefoundation.org/"
      >
        Green Hope Foundation
      </motion.a>
      , and am actively involved as an AI tutor at the{" "}
      <motion.a
        whileHover={{ textDecorationLine: "underline", color: "#FF6347" }}
        href="https://www.caltechy.org/"
      >
        Caltech Y
      </motion.a>{" "}
      (read more about this in my extracurriculars and teaching pages). Outside
      of academia, I enjoy playing the piano (and more recently, the ukulele),
      and going out for a run/ride while preparing for the occasional duathlon.
      During my time at Caltech, I ran for the cross-country and track team. In
      2021, I was proud to cross the finish line at Elsinore castle in Denmark
      to win 2nd place at the Elsinore 70.3 Ironman.
    </p>
    <h3>Inspirations</h3>
    <p>
      Richard Feynman once said that "the more you know, the more you know what
      you don't know". I'm truly reminded every day of just how little I really
      know and how many global problems there are out there that are just
      waiting to be solved. Yet, my thirst for problem solving and my hunger to
      understand the unknown is letting me rise to this challenge. You will find
      below the names and links to profiles of great people whose works and
      stories serve as an inspiration.
    </p>
    <InspirationsContainer>
      <Row className="px-5">
        <Col md={4} className="mb-3">
          <PersonImageCaption
            href="https://en.wikipedia.org/wiki/Maryam_Mirzakhani"
            src={mirzakhani}
            name="Maryam Mirzakhani"
          />
          <PersonImageCaption
            href="https://en.wikipedia.org/wiki/Paul_Erd%C5%91s"
            src={erdos}
            name="Paul Erdős"
          />
          <PersonImageCaption
            href="https://en.wikipedia.org/wiki/Emmy_Noether"
            src={noether}
            name="Emmy Noether"
          />
        </Col>
        <Col md={4} className="mb-3">
          <PersonImageCaption
            href="https://en.wikipedia.org/wiki/Alan_Turing"
            src={turing}
            name="Alan Turing"
          />
          <PersonImageCaption
            href="https://en.wikipedia.org/wiki/Sophie_Germain"
            src={germain}
            name="Sophie Germain"
          />
          <PersonImageCaption
            href="https://en.wikipedia.org/wiki/Srinivasa_Ramanujan"
            src={ramanujan}
            name="Srinivasa Ramanujan"
          />
        </Col>
        <Col md={4} className="mb-3">
          <PersonImageCaption
            href="https://en.wikipedia.org/wiki/Claude_Shannon"
            src={shannon}
            name="Claude Shannon"
          />
          <PersonImageCaption
            href="https://en.wikipedia.org/wiki/Marie_Curie"
            src={curie}
            name="Marie Curie"
          />
        </Col>
      </Row>
    </InspirationsContainer>
    {/* <Row>
        <Col md={4} className="mb-3">
            <h6 className="text-center">Claude Shannon</h6>
            <a href="https://en.wikipedia.org/wiki/Claude_Shannon"><motion.img whileHover={{ scale: 1.01 }} src={shannon} className="d-block w-100 rounded" /></a>
        </Col>
        <Col md={4} className="mb-3">
            <h6 className="text-center">Paul Erdős</h6>
            <a href="https://en.wikipedia.org/wiki/Paul_Erd%C5%91s"><motion.img whileHover={{ scale: 1.01 }} src={erdos} className="d-block w-100 rounded" /></a>
        </Col>
        <Col md={4} className="mb-3">
            <h6 className="text-center">Emmy Noether</h6>
            <a href="https://en.wikipedia.org/wiki/Emmy_Noether"><motion.img whileHover={{ scale: 1.01 }} src={noether} className="d-block w-100 rounded" /></a>
        </Col>
    </Row> */}
  </>
);

const AboutMe = () => {
  // const { scrollYProgress } = useScroll()
  // const [y, setY] = useState(0);
  // const bottom = 1 / 3
  // const rotate = useTransform(
  //     scrollYProgress,
  //     [0, bottom],
  //     [-30, 70]
  // )
  // const rotate2 = useTransform(
  //     scrollYProgress,
  //     [0, bottom],
  //     [-65, 70]
  // )
  // const rotate3 = useTransform(
  //     scrollYProgress,
  //     [0, bottom],
  //     [-100, 70]
  // )
  // const rotate4 = useTransform(
  //     scrollYProgress,
  //     [0, bottom],
  //     [-135, 70]
  // )
  // const rotate5 = useTransform(
  //     scrollYProgress,
  //     [0, bottom],
  //     [-170, 70]
  // )

  // const pos = useTransform(
  //     scrollYProgress,
  //     [0, bottom],
  //     [640, 0]
  // )
  // const scale = useTransform(
  //     scrollYProgress,
  //     [0, bottom],
  //     [1.7, 1.3]
  // )

  // const tint = useTransform(
  //     scrollYProgress,
  //     [bottom, 5 / 4 * bottom],
  //     [0, 1]
  // )
  // const descPos = useTransform(
  //     scrollYProgress,
  //     value => `${((2 * bottom) - value) / (2 * bottom) * 100}%`
  // )

  // const fixedStyles = { translateX: 0, translateY: 0, translateZ: 0, skewX: 0, skewY: 2, transformOrigin: 'left center', fontSize: '4vw', fontWeight: 500 }

  // useMotionValueEvent(scrollYProgress, "change", (latest) => {
  //     setY(latest)
  //     // console.log(latest)
  //     console.log(((2 * bottom) - latest) / (2 * bottom))
  // })

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  const showCols = useMediaQuery({
    query: "(min-width: 992px)",
  });

  return (
    // <>
    //     <GlobalNavBar style={{ position: 'relative', zIndex: 8000 }} />
    //     <div style={{ position: 'relative', overflow: 'visible', height: 'calc(3 * 100vh)', backgroundColor: 'black' }}>
    //         {y < 2 * bottom && <div style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100%', translate: 0 }}>
    //             {y > 0 && y < bottom && <div initial={{ opacity: 0 }} animate={{ opacity: 1 }} style={{ position: 'absolute', top: 0, left: 0, height: '100vh', width: '100%', backgroundColor: 'black' }}>
    //                 <motion.div style={{ width: '100%', position: 'relative', translateX: 0, translateY: 0, translateZ: 0, perspective: 800, transformOrigin: 'left top', top: pos, scaleX: scale, scaleY: scale, color: 'white' }} className='p-5'>
    //                     <motion.h3 style={{ ...fixedStyles, rotateY: rotate }}>An explorer of the unknown.</motion.h3>
    //                     <motion.h3 style={{ ...fixedStyles, rotateY: rotate2, }}>I am passionate about:</motion.h3>
    //                     <motion.h3 style={{ ...fixedStyles, rotateY: rotate3, }}>Creating robust ML algorithms</motion.h3>
    //                     <motion.h3 style={{ ...fixedStyles, rotateY: rotate4, }}>Discovering mathematical methods</motion.h3>
    //                     <motion.h3 style={{ ...fixedStyles, rotateY: rotate5, }}>Redefining theoretical frontiers</motion.h3>
    //                 </motion.div>
    //             </div>}
    //             {y >= bottom && y < 2 * bottom && <MotionSectionContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} bgImg={emile1}>
    //                 <motion.div style={{ height: '100vh', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.65)', opacity: tint }}>
    //                     <motion.div style={{ position: 'absolute', bottom: descPos }} className='m-3'>
    //                         <>
    //                             <h5>Hello! My name is Emile Timothy, and I'm a senior at Caltech majoring in Computer Science. I do research at the Theory of Computation Group and the Rigorous Systems Research Group (RSRG), and am a member of the Caltech Data Science team. My interests include working on research or coding projects, playing the Piano, thinking about wild hypothetical scenarios, and exploring boba stores and running trails around Pasadena and Los Angeles as a member of the Caltech XC/TF team. I occasionally post pictures and share my thoughts on Instagram and Twitter.</h5>

    //                             <h5>My research with Chris Umans focuses on constructing a framework to study the model of expander random walks, which is a useful tool in derandomization. My research is devoted to enlarging the pseudorandomness of expander random walks by finding test-functions that are unable to distinguish between expander random walks and truly random walks. We are currently trying to show that all functions computed by
    //                                 circuits are fooled by expander random walks. My research with Adam Wierman focuses on using perturbation analysis to prove stability bounds for model-predictive control in noisy systems, and deriving linear feedback policies for the regularized online balanced descent (R-OBD) algorithm. Our work is currently awaiting review at . My research with Yaser Abu-Mostafa focuses on explaining why neural-network multi-classifiers outperform binary classifiers. To explain this, we are developing a theory of mutual cross-regularization between classes to prove a higher learning bound for multi-classifiers and training neural networks on large datasets (MNIST) to find the scope of this theory.</h5>
    //                         </>
    //                     </motion.div>
    //                 </motion.div>
    //             </MotionSectionContainer>}
    //             {/* {y >= 2 * bottom && <MotionSectionContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} style={{ backgroundColor: 'white' }}>
    //             </MotionSectionContainer>} */}
    //         </div>}
    //     </div>
    //     <div className='p-5'>
    //         <Row className='mb-3'>
    //             <Col md={4}>
    //                 <h4>My Inspirations</h4>
    //                 <Row>
    //                     <Col className='h-100'>
    //                         <motion.img whileHover={{ scale: 1.1 }} src={shannon} className='w-100 rounded' />
    //                         <motion.img whileHover={{ scale: 1.1 }} src={goog} className='w-100 mt-3 rounded' />
    //                         <motion.img whileHover={{ scale: 1.1 }} src={tim} className='w-100 mt-3 rounded' />
    //                     </Col>
    //                     <Col className='h-100'>
    //                         <motion.img whileHover={{ scale: 1.1 }} src={turing} className='w-100 rounded' />
    //                         <motion.img whileHover={{ scale: 1.1 }} src={sydney} className='w-100 mt-3 rounded' />
    //                         <motion.img whileHover={{ scale: 1.1 }} src={lamarr} className='w-100 mt-3 rounded' />
    //                     </Col>
    //                 </Row>
    //             </Col>
    //             <Col md="auto">
    //                 <h4>Recent Books</h4>
    //                 <FeaturedItem img={heard} title="Heard On The Street" author="Timothy Falcon" />
    //                 <FeaturedItem img={fooled_by_randomness} title="Fooled By Randomness" author="Nassim Taleb" />
    //                 <FeaturedItem img={bed_of_procrustes} title="The Bed of Procrustes" author="Nassim Taleb" />
    //                 <FeaturedItem img={surely_youre_joking} title="Surely You're Joking Mr. Feynman" author="Richard Feynman" />
    //             </Col>
    //             <Col md="auto">
    //                 <h4>Favorite Music</h4>
    //                 <FeaturedItem img={springday} title="Spring Day" author="BTS" />
    //                 <FeaturedItem img={dansemacabre} title="Danse Macabre" author="Saint-Saëns (Liszt's piano rendition)" />
    //                 <FeaturedItem img={vivalavida} title="Viva La Vida" author="Coldplay" />
    //                 <FeaturedItem img={dynamite} title="Dynamite" author="BTS" />
    //             </Col>
    //         </Row>
    //         <div>
    //             <h4>Tags</h4>
    //             {['Complexity Theory', 'Machine Learning', 'Derandomization', 'Convex Optimization', 'Control Theory', 'Expanders', 'PRGs'].map(tag =>
    //                 <Badge className="me-3" key={tag}>{tag}</Badge>
    //             )}
    //         </div>
    //     </div>
    //     <GlobalFooter />
    // </>
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      style={{ position: "relative", minHeight: "100vh" }}
    >
      <>
        <GlobalNavBar />
        <Container fluid={true} className="py-4">
          {!showCols && (
            <>
              <h3>About Me</h3>
              <div className="mainImg mx-auto" style={{ maxWidth: 300 }}>
                <img className="w-100 rounded" alt="" src={emile1} />
              </div>
              {mainContent}
            </>
          )}
          {showCols && (
            <Row>
              <Col md={4} className="px-5">
                <div className="mainImg" style={{ maxWidth: 300 }}>
                  <img className="w-100 rounded" alt="" src={emile1} />
                </div>
              </Col>
              <Col md={8} className="pe-5">
                {/* <h3>About Me</h3> */}
                {/* {mainContent} */}
                {about_me}
                <InspirationsContainer>
                  <Row className="px-5">
                    <Col md={4} className="mb-3">
                      <PersonImageCaption
                        href="https://en.wikipedia.org/wiki/Maryam_Mirzakhani"
                        src={mirzakhani}
                        name="Maryam Mirzakhani"
                      />
                      <PersonImageCaption
                        href="https://en.wikipedia.org/wiki/Paul_Erd%C5%91s"
                        src={erdos}
                        name="Paul Erdős"
                      />
                      <PersonImageCaption
                        href="https://en.wikipedia.org/wiki/Emmy_Noether"
                        src={noether}
                        name="Emmy Noether"
                      />
                    </Col>
                    <Col md={4} className="mb-3">
                      <PersonImageCaption
                        href="https://en.wikipedia.org/wiki/Alan_Turing"
                        src={turing}
                        name="Alan Turing"
                      />
                      <PersonImageCaption
                        href="https://en.wikipedia.org/wiki/Sophie_Germain"
                        src={germain}
                        name="Sophie Germain"
                      />
                      <PersonImageCaption
                        href="https://en.wikipedia.org/wiki/Srinivasa_Ramanujan"
                        src={ramanujan}
                        name="Srinivasa Ramanujan"
                      />
                    </Col>
                    <Col md={4} className="mb-3">
                      <PersonImageCaption
                        href="https://en.wikipedia.org/wiki/Claude_Shannon"
                        src={shannon}
                        name="Claude Shannon"
                      />
                      <PersonImageCaption
                        href="https://en.wikipedia.org/wiki/Marie_Curie"
                        src={curie}
                        name="Marie Curie"
                      />
                    </Col>
                  </Row>
                </InspirationsContainer>
              </Col>
            </Row>
          )}
        </Container>
        <GlobalFooter bottomSticky={false} />
      </>
    </motion.article>
  );
};

export default AboutMe;
