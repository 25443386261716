import { motion } from "framer-motion";
import React, { useEffect } from "react";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from "react-router-dom";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import serengeti3 from "../../img/bg/serengeti3.webp";

const StylesDiv = styled.div`
  & {
    overflow-x: auto;
  }

  & img {
    width: 100%;

    @media only screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  & .grid-layout {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (min-width: 990px) {
      // grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const ResponsiveContainer = styled.div`
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    position: relative;
    margin-top: -10%;
    margin-bottom: 2rem;
  }
  @media (min-width: 990px) {
    width: 70%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.cover});
  background-size: cover;
  height: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    align-items: normal;
    height: 600px;
  }
`;

const Header = styled.div`
  height: fit-content;
  background-color: white;
  color: black;
  font-weight: bold;
  // mix-blend-mode: screen;
  font-family: "Oswald", sans-serif;
  text-shadow: 3px 3px 3px #ababab;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  padding: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 3rem;
    width: 60%;
  }
`;

const StyledArticle = styled(motion.article)`
  background-color: #f1f1f1;
`;

const Hurdle2 = () => {
  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <div>
      <StyledArticle
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <>
          <GlobalNavBar />
          <HeaderContainer cover={serengeti3}>
            <Header>Physics 11 Hurdle 2: Fire in the Serengeti</Header>
          </HeaderContainer>
          <ResponsiveContainer className="container p-3">
            <StylesDiv>
              <p>
                There’s a renowned freshman course at Caltech called{" "}
                <a href="https://www.quora.com/What-is-it-like-to-take-Caltechs-Physics-11-class">
                  Physics 11
                </a>
                , which is a sort of think tank to get students involved in
                research early, and to explore interesting ideas. The catch is
                that to get in, you’d have to submit solutions to two ‘hurdles’,
                which are open-ended questions that you’re meant to think
                carefully about, and only the students with the most creative
                solutions are awarded entry into the class.
              </p>
              <p>
                Every year, about 50 students submit solutions to the hurdles,
                and only 4-6 people get in - so, I was shocked and delighted
                when I found out that my solutions had been accepted. During the
                3-quarter sequence, we would read a paper each week and meet
                every Tuesday evening to discuss the paper, which would often
                reflect new ways to model phenomena across Math, Physics,
                Biology, CS, and Finance. We often created our own models which
                we sketched out on a blackboard and discussed.
              </p>
              <p>
                It was a privilege to learn from Professors{" "}
                <a href="https://www.rpgroup.caltech.edu/">Rob Phillips</a> and{" "}
                <a href="https://en.wikipedia.org/wiki/David_J._Stevenson">
                  Dave Stevenson
                </a>{" "}
                about the methods by which new scientific research ideas
                originated and to get intuition for ways to develop them. I was
                also fortunate to get awarded with the Richard Brewer fellowship
                to pursue a research project during the Summer of 2020 with{" "}
                <a href="https://www.ligo.caltech.edu/">LIGO</a> and{" "}
                <a href="https://labcit.ligo.caltech.edu/~rana/">
                  Prof. Rana Adhikari
                </a>
                , where I worked on developing an “acoustic invisibility cloak”
                for the gravitational-wave detector. Here, I present my
                solutions to the Physics 11 hurdles for the 2019-2020 academic
                year.
              </p>
              <p>
                For the second hurdle into the Physics 11 class at Caltech,
                Professors Rob Phillips and Dave Stevenson asked us to model the
                relation between the number of rinderpest virus’, wildebests,
                lions, elephants, and trees in the Serengeti ecosystem, and to
                predict the locations of wildfire outbreaks (which is dependent
                on the actions of other species in the ecosystem).
              </p>
              <p>
                Essentially, the spectacular migration of wildebeests within the
                Serengeti implies that the Wildebeests would consume the grass
                which in turn fuels the spontaneous wildfire outbreaks, so the
                Wildebeests played a role in mitigating forest fires by
                consuming the source of their fuel. However, the presence of the
                Rinderpest virus which solely targets Wildebests meant that
                there were fewer Wildebeests available to eat the grass, which
                caused the number of wildfires to increase. However, another
                confounding factor in this problem was the existence of
                predators in the ecosystem. The Serengeti lions are known for
                eating the Wildebests and Elephants; however, many of them die
                in the wildfires which implies a temporarily drop in the rate of
                decrease number of Wildebeests and Elephants being eaten.
              </p>
              <p>
                This problem was quite stressful since my first two
                computational approaches failed to work; however, I had a lot of
                fun thinking about this problem, and even had some luck by using
                a neat perturbation idea with a linear-algebraic approach (the
                adomian decompositon of the Leslie matrix where a time-dependent
                population histogram of the species were modeled as vectors at
                each discrete time step). I’ve attached my submission to this
                problem below.
              </p>
              <iframe
                src="https://drive.google.com/file/d/1UYXY1j7I0LJJftKqsRfIxS33XqwwiXQY/preview"
                width="100%"
                height="720"
              ></iframe>
            </StylesDiv>
          </ResponsiveContainer>
          <GlobalFooter />
        </>
      </StyledArticle>
      <ScrollRestoration />
    </div>
  );
};

export default Hurdle2;
